import {
  Heading,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import NewsItem from "../components/NewsItem";

const IMAGE =
  "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

export default function News() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/content/items/news?sort=%7Bdate%3A+-1%7D`).then((response) => {
      setNewsData(response.data);
      setIsLoading(false);
    });
  }, []);
  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
    >
      <Heading>NEWS</Heading>
      <Tabs
        variant="soft-rounded"
        colorScheme="blue"
        onChange={(index) => setPageIndex(index)}
        index={pageIndex}
        w={"full"}
        maxW={1185}
        justify={"center"}
        align={"center"}
      >
        <TabList>
          <Tab>LATEST</Tab>
          <Tab>INFO</Tab>
          <Tab>EVENT</Tab>
          <Tab>UPDATE</Tab>
        </TabList>
        {isLoading ? <Loading /> : null}
        <TabPanels>
          <TabPanel>
            <Wrap
              templateColumns="repeat(3, 3fr)"
              spacing={10}
              zIndex={999}
              marginTop={50}
              justify={"start"}
              p={10}
            >
              {newsData.map((data) => {
                const link = data.content === "" ? "" : `/news/${data._id}`;
                return (
                  <WrapItem key={data._id}>
                    <Link
                      href={link}
                      style={{ textDecoration: "none" }}
                      onClick={(e) =>
                        data.content === "" ? e.preventDefault() : {}
                      }
                    >
                      <NewsItem
                        title={data.title}
                        image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                        date={data.date}
                        type={data.type}
                      />
                    </Link>
                  </WrapItem>
                );
              })}
            </Wrap>
          </TabPanel>
          <TabPanel>
            <Wrap
              templateColumns="repeat(3, 3fr)"
              spacing={10}
              zIndex={999}
              marginTop={50}
              justify={"start"}
              p={10}
            >
              {newsData
                .filter((e) => e.type === "info")
                .map((data) => {
                  const link = data.content === "" ? "" : `/news/${data._id}`;
                  return (
                    <WrapItem key={data._id}>
                      <Link
                        href={link}
                        style={{ textDecoration: "none" }}
                        onClick={(e) =>
                          data.content === "" ? e.preventDefault() : {}
                        }
                      >
                        <NewsItem
                          title={data.title}
                          image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                          date={data.date}
                          type={data.type}
                        />
                      </Link>
                    </WrapItem>
                  );
                })}
            </Wrap>
          </TabPanel>
          <TabPanel>
            <Wrap
              templateColumns="repeat(3, 3fr)"
              spacing={10}
              zIndex={999}
              marginTop={50}
              justify={"start"}
              p={10}
            >
              {newsData
                .filter((e) => e.type === "event")
                .map((data) => {
                  const link = data.content === "" ? "" : `/news/${data._id}`;
                  return (
                    <WrapItem key={data._id}>
                      <Link
                        href={link}
                        style={{ textDecoration: "none" }}
                        onClick={(e) =>
                          data.content === "" ? e.preventDefault() : {}
                        }
                      >
                        <NewsItem
                          title={data.title}
                          image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                          date={data.date}
                          type={data.type}
                        />
                      </Link>
                    </WrapItem>
                  );
                })}
            </Wrap>
          </TabPanel>
          <TabPanel>
            <Wrap
              templateColumns="repeat(3, 3fr)"
              spacing={10}
              zIndex={999}
              marginTop={50}
              justify={"center"}
              p={10}
            >
              {newsData
                .filter((e) => e.type === "update")
                .map((data) => {
                  const link = data.content === "" ? "" : `/news/${data._id}`;
                  return (
                    <WrapItem key={data._id}>
                      <Link
                        href={link}
                        style={{ textDecoration: "none" }}
                        onClick={(e) =>
                          data.content === "" ? e.preventDefault() : {}
                        }
                      >
                        <NewsItem
                          title={data.title}
                          image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                          date={data.date}
                          type={data.type}
                        />
                      </Link>
                    </WrapItem>
                  );
                })}
            </Wrap>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Wrap>
  );
}
