import { GridItem, Grid } from "@chakra-ui/react";
import React from "react";
import Highlight from "../components/Highlight";
import HomeHeader from "../components/HomeHeader";
function Home() {
  return (
    <Grid>
      <GridItem>
        <HomeHeader />
      </GridItem>
      <Highlight />
    </Grid>
  );
}

export default Home;
